define("discourse/plugins/discourse-category-experts/discourse/connectors/advanced-search-options-below/category-experts-search-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return component.siteSettings.enable_category_experts;
    },
    setupComponent() {
      this.set("canSeeIsQuestionFilter", this.currentUser && (this.currentUser.staff || this.currentUser.expert_for_category_ids && this.currentUser.expert_for_category_ids.length));
    },
    actions: {
      onChangeCheckBox(path, fn, event) {
        this.onChangeSearchedTermField(path, fn, event.target.checked);
      }
    }
  };
});