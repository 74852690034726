define("discourse/plugins/discourse-category-experts/discourse/connectors/user-profile-controls/endorsement-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args) {
      return !!args.model.category_expert_endorsements;
    }
  };
});