define("discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "review.expert_group_chooser_modal.title"}}
    class="expert-group-chooser-modal"
    {{did-insert this.loadGroups}}
  >
    <TapTileGrid>
      {{#each this.groupOptions as |group|}}
        <TapTile @tileId={{group.id}} @onChange={{action "setGroupId"}}>
          {{group.name}}
        </TapTile>
      {{/each}}
    </TapTileGrid>
  </DModal>
  */
  {
    "id": "nLZdTiZ/",
    "block": "[[[8,[39,0],[[24,0,\"expert-group-chooser-modal\"],[4,[38,2],[[30,0,[\"loadGroups\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"review.expert_group_chooser_modal.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"groupOptions\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@tileId\",\"@onChange\"],[[30,2,[\"id\"]],[28,[37,7],[[30,0],\"setGroupId\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"group\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"tap-tile-grid\",\"each\",\"-track-array\",\"tap-tile\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser.hbs",
    "isStrictMode": false
  });
  class ExpertGroupChooserModal extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "groupId", [_tracking.tracked]))();
    #groupId = (() => (dt7948.i(this, "groupId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "groupOptions", [_tracking.tracked], function () {
      return null;
    }))();
    #groupOptions = (() => (dt7948.i(this, "groupOptions"), void 0))();
    loadGroups() {
      const expertGroupIds = this.args.model.reviewable.category.custom_fields.category_expert_group_ids.split("|");
      (0, _ajax.ajax)("/groups.json").then(response => {
        this.groupOptions = response.groups.filter(group => expertGroupIds.includes(group.id.toString()));
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadGroups", [_object.action]))();
    setGroupId(val) {
      this.args.model.reviewable.set("group_id", val);
      this.args.closeModal();
      this.args.model.performConfirmed(this.args.model.action);
    }
    static #_4 = (() => dt7948.n(this.prototype, "setGroupId", [_object.action]))();
  }
  _exports.default = ExpertGroupChooserModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpertGroupChooserModal);
});