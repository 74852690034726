define("discourse/plugins/discourse-category-experts/discourse/connectors/above-review-filters/endorsed-username-to-filters", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    updateEndorsedUsername(usernames) {
      (0, _object.set)(this, "additionalFilters.endorsed_username", usernames[0]);
    }
  }, [["method", "updateEndorsedUsername", [_object.action]]]);
});